
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate, translateKey } from "@/hooks/TranslateService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-overview",
  components: {},
  setup() {
    const { locale } = useI18n();

    const store = useStore();

    const toYear = reactive(
      computed(() => {
        return localStorage.getItem("to_year_admin");
      })
    );

    const selectedUser = reactive(
      computed(() => {
        return JSON.parse(
          localStorage.getItem("simulate_user") as string
        );
      })
    );

    const accountInfo = reactive({
      userProfile: computed(() => {
        return {
          id: selectedUser.value.id,
          user_name: selectedUser.value.user_name,
          email: selectedUser.value.email,
          ruolo_aziendale: selectedUser.value.ruolo_aziendale,
          telefono: selectedUser.value.telefono,
        }
      }),
      companyProfile: computed(() => {
        return {
          denom: selectedUser.value.denom,
          p_iva: selectedUser.value.p_iva,
        }
      }),
      companyYearlyParams: computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year.toString() === (toYear.value as string)) {
            return yearlyParameters;
          }
        }
        return null;
      }),
    });

    const companySectorDesc = ref(
      computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year.toString() === (toYear.value as string)) {
            return locale.value === "it"
              ? yearlyParameters.settore.descrizione
              : yearlyParameters.settore.descrizione_en
          }
        }
        return null;
      })
    )

    const statusAssociatedText = ref(
      computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year.toString() === (toYear.value as string)) {
            return yearlyParameters.azienda_yearly_parameters.is_associated
              ? translate("CHANGE_ASSOCIATED_OPTION")
              : translate("CHANGE_NOT_ASSOCIATED_OPTION");
          }
        }
        return null;
      })
    );

    const statusQuotedText = ref(
      computed(() => {
        for (const yearlyParameters of selectedUser.value.yearlyParameters) {
          if (yearlyParameters.azienda_yearly_parameters.year.toString() === (toYear.value as string)) {
            return yearlyParameters.azienda_yearly_parameters.is_quoted 
              ? translate("CHANGE_QUOTED_OPTION")
              : translate("CHANGE_NOT_QUOTED_OPTION");
          }
        }
        return null;
      })
    );

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year_admin")
        );
      }, 1000);

      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    return {
      translate,
      translateKey,
      ...toRefs(accountInfo),
      toYear,
      selectedUser,
      companySectorDesc,
      statusAssociatedText,
      statusQuotedText,
    };
  },
});
